import { useEffect, useState } from "react"
import { Button, Dialog, Container, IconButton, Typography, Box, Slider, Grid, CardMedia } from "@material-ui/core"
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ContentService from '../../services/contentService'
import { Toast } from "../toastify";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import vettageLogo from '../../static/vlogo.png'
import useWindowDimensions from '../useWindowDimensions'

const muiTheme = createMuiTheme({
  overrides:{
    MuiSlider: {
      thumb:{
      color: "#000",
      },
      track: {
        color: '#fed108'
      },
      rail: {
        color: '#000'
      }
    }
}
});

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    viewButton: {
        border:'1px solid #FFF', 
        backgroundColor: '#000', 
        color: '#ffa903',
        margin: '0px',
        padding: '10px !important',
        '&:hover': {
            backgroundColor: '#FFF',
            color: '#000'
        },
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Box flexWrap="wrap">
            <Typography style={{wordBreak: "break-all"}} variant="h6">{children}</Typography>
        </Box>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

const DialogContent = withStyles((theme) => ({
root: {
    padding: theme.spacing(2),
},
}))(MuiDialogContent);


const ViewButton = withStyles(styles)((props) => {
    const { classes, onClick } = props;
    return (
        <Box m={1}>
            <Button className={classes.viewButton} onClick={onClick}>
                View / Rate Story
            </Button>
        </Box>
    );
  });


const fullScreen = () => {
    var iframe = document.querySelector('#content_iframe');
    // Do fullscreen
    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen();
    } else if (iframe.mozRequestFullScreen) {
      iframe.mozRequestFullScreen();
    } else if (iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen();
    }
}
const renderContent = (type, link) => {
    return (
        <Box>
            <iframe
                id="content_iframe"
                width="100%"
                max-width='auto'
                height="480"
                src={link}
                frameBorder="0"
                allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title="Vettage Embed"
            />
        </Box>
    )
}

const ContentView = ({ data, setData, onClose, open }) => {
    const [newsworthiness, setNewsworthiness] = useState(0)
    const [credibility, setCredibility] = useState(0)
    const [timeliness, setTimeliness] = useState(0)
    const [presentation, setPresentation] = useState(0)
    const [canRate, setCanRate] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const { width, height } = useWindowDimensions()
    const [hide, setHide] = useState(false)

    const avgRatingOptions = [
        {
            "title": "Average Newsworthiness",
            "id": "rating-newsworthiness",
            "value": data?.average_rating?.avg_newsworthiness*10,
        },
        {
            "title": "Average Credibility",
            "id": "rating-credibility",
            "value": data?.average_rating?.avg_newsworthiness*10,
        },
        {
            "title": " Average Timeliness",
            "id": "rating-timeliness",
            "value": data?.average_rating?.avg_newsworthiness*10,
        },
        {
            "title": "Average Presentation",
            "id": "rating-presentation",
            "value": data?.average_rating?.avg_newsworthiness*10,
        },
    ]

    const rating_options = [
        {
            "title": "Newsworthiness",
            "id": "rating-newsworthiness",
            "value": newsworthiness,
            "setter": setNewsworthiness,
        },
        {
            "title": "Credibility",
            "id": "rating-credibility",
            "value": credibility,
            "setter": setCredibility,
        },
        {
            "title": "Timeliness",
            "id": "rating-timeliness",
            "value": timeliness,
            "setter": setTimeliness,
        },
        {
            "title": "Presentation",
            "id": "rating-presentation",
            "value": presentation,
            "setter": setPresentation,
        },
    ]

    const viewAndRate = () => {
        if(!localStorage.getItem('vettageToken')){
            Toast("error", "You must register as a Subscriber to do this.")
        }
        else {
            const roles = localStorage.getItem("roles").split(",");
            if (roles.includes("SUB_FREE") || roles.includes("SUB_SUBSCRIPTION") || roles.includes("SUB_PERVIEW")) {
                ContentService.checkPermission(data.id).then(result => {
                    if(result.status === 200){
                        setHide(true);
                    }
                    else {
                        if (roles.includes("SUB_FREE")){
                            Toast("error", "A free subscriptions only allows 1 view per month.")
                        }
                        else if (roles.includes("SUB_PERVIEW")){
                            Toast("error", "You have 0 tokens remaining.")
                        }
                    }
                })
            }
            else {
                Toast("error", "You must register as a Subscriber to do this.")
            }
        }
    }

    const renderRatingOptions = () => {
        return (<>
            {rating_options.map(item => {
                return (
                    <Box>
                        <Typography id={item.id} gutterBottom>
                        {item.title}:
                        </Typography>
                        <Box>
                            <ThemeProvider theme={muiTheme}>
                                <Slider
                                defaultValue={0}
                                aria-labelledby={item.id}
                                step={5}
                                disabled={disabled}
                                data-name="newsworthiness"
                                onChange={(e, newValue) => {item.setter(newValue)}}
                                value={item.value}
                                valueLabelDisplay={data?.rating ? 'on' : 'auto'}
                                />
                            </ThemeProvider>
                        </Box>
                    </Box>
                )
            })}</>
    )}

    const renderAverageRatingOptions = () => {
            return (<>
                {avgRatingOptions.map(item => {
                    return (
                        <Box>
                            <Box style={{ border: '1px solid #000', borderRadius: '10px' }} p={1.2} m={1}>
                                <Typography id={item.id} gutterBottom>
                                {item.title}: {item.value}%
                                </Typography>
                            </Box>
                        </Box>
                    )
                })}</>
    )}

    useEffect(() => {
        setNewsworthiness(0)
        setCredibility(0)
        setTimeliness(0)
        setPresentation(0)
        setCanRate(data?.can_rate)
        setDisabled(data?.rating ? true : false)
        if(data?.rating || !data?.can_rate){
            setCanRate(false)
            if(data?.rating){
                setNewsworthiness(data.rating?.newsworthiness*10)
                setCredibility(data.rating?.credibility*10)
                setTimeliness(data.rating?.timeliness*10)
                setPresentation(data.rating?.design_quality*10)
            }
        }
    }, [data])


    const rateContent = () => {
        ContentService.rateContent(data.id, newsworthiness, credibility, timeliness, presentation)
        .then(result => {
            if(result.status === 201){
                setData({...data, "rating": result.data})
                Toast("success", "The rating was submitted.")
            }else if(result.status === 403){
                setCanRate(false)
                Toast("error", result.data?.error)
            }else{
                Toast("error", "There was an error with the request.")
            }
        })
    }

    const onCloseDouble = () => {
        setHide(false)
        onClose()
    }

    return (
        <div>
            {data && 
            <Container >
                <Dialog onClose={onCloseDouble} fullWidth maxWidth="lg" open={open}>
                    { !hide && (
                        <Box border='2px solid #FFF' component="div" flexWrap="wrap" justifyContent="center" fullWidth textAlign='center' alignItems="center"  style={{backgroundColor: '#000'}} p={2}>
                            <img src={data.preview} style={{ width: '80%', height: '100%' }}/>
                            <Box textAlign='center'>
                                <Box>
                                    <Typography variant='h6' style={{ fontWeight: 'bold', textTransform: 'uppercase', color: '#FFF'}}>
                                    {data.title}</Typography>
                                    <ViewButton onClick={viewAndRate}> </ViewButton>
                                </Box>
                                <Typography variant='body' style={{color: '#FFF'}}>{data.description}</Typography>
                            </Box>
                        </Box>
                    )}
                    { hide && (
                    <Box component="div" flexWrap="wrap" justifyContent="center" fullWidth alignItems="center" p={{ xs: 2, sm: 3, md: 4 }} minHeight='80vh'>
                            <Box component="div" textAlign='center'>
                                <DialogTitle id="dialog-title" onClose={onCloseDouble}>{data?.title}</DialogTitle>
                                <Grid container lg={10} md={10} xs={12} sm={12} style={{margin: 'auto'}}>
                                    <Grid item lg={6} md={6} xs={12} sm={12}>
                                        <Typography>Editor: {data.editor}</Typography>
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12} sm={12}>
                                        <Typography>Contributors: {data.editor}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <DialogContent dividers>
                                <Grid container lg={12} md={12} direction="row">
                                    <Grid item lg={7} md={7} sm={12} xs={12} >
                                        <Box>
                                            {renderContent(data.content_type, data.link)}
                                            <Box textAlign='center'>
                                                <Button onClick={fullScreen}>Fullscreen</Button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={12} xs={12} style={{ margin: '0 auto' }}>
                                        <Box p={2}>
                                        {data?.can_rate || data?.rating ? 
                                            <Box>
                                                <Box textAlign='center'>
                                                    <Typography variant='h6' gutterBottom>
                                                    Rating:
                                                    </Typography>
                                                </Box>
                                                {renderRatingOptions()}
                                            </Box> : null}
                                            {!data?.can_rate && data?.average_rating ? 
                                            <Box>
                                                <Box textAlign='center'>
                                                    <Typography variant='h6' gutterBottom>
                                                    Average Rating
                                                    </Typography>
                                                </Box>
                                                {renderAverageRatingOptions()}
                                            </Box> : null}
                                            <Box textAlign='center'>
                                                {canRate &&
                                                    <Button style={{ color: '#000', backgroundColor: '#FFF', border: '1px solid #000' }}
                                                    onClick={rateContent}>Rate</Button>
                                                }
                                                {!data?.rating && !canRate && !data?.average_rating && 
                                                    <Typography>The rating period for this content has expired.</Typography>
                                                }
                                            </Box>
                                        </Box>
                                        <Box textAlign='right' mr={width < 960 ? '' : '-30%'} mt={width < 960 ? '' : '30%'}>
                                            <img width={width < 960 ? '10%' : '20%'} src={vettageLogo} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Box>
                    )}
                </Dialog>
            </Container>}
        </div>
    )
}

export default ContentView
