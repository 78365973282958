import { Component } from 'react';

import validator from 'validator';
import FinalContentForm from "./FinalContentForm";
import EditorsService from '../../services/editorsService';
import { CustomToast, Toast } from '../../components/toastify'
import { Container, Box } from '@material-ui/core'

class UploadPage extends Component{
    constructor(props) {
        super(props)
      }

    checkValidUrl = (url) => {
        var types = ['jpg','jpeg','tiff','png','gif','bmp'];
        var parts = url.split('.');
        
        var extension = parts[parts?.length-1];

        if(types.indexOf(extension) !== -1) {
            return true;   
        }else{
            return false;
        }
    }
    
    handleUpload = (e, data) => {
        e.preventDefault();

        if(
            data.content_type !== "" &&
            data.tags?.length !== 0 &&
            validator.isURL(data.link, {require_valid_protocol: false}) &&
            this.checkValidUrl(data.thumbnail) &&
            validator.isURL(data.preview, {require_valid_protocol: false}) &&
            this.checkValidUrl(data.preview) &&
            data.copyright == true &&
            data.final_content_address.country !==  "" &&
            data.final_content_address.state !==  "" &&
            data.final_content_address.city !==  "" &&
            data.description !== "" &&
            data.title !== "" &&
            data.link !== ""
        ){
            if(!data.preview.includes('http') && !data.preview.includes('https')){
                data.preview = "http://" + data.preview
            }
            if(!data.thumbnail.includes('http') && !data.thumbnail.includes('https')){
                data.thumbnail = "http://" + data.thumbnail
            }
            if(!data.link.includes('http') && !data.link.includes('https')){
                data.link = "http://" + data.link
            }

            if(data.link.includes('vimeo.com')){
                let oldLink = data.link
                data.link = 'https://player.vimeo.com/video/' + oldLink.split('/')[oldLink.split('/').length-1]
            }

            EditorsService.uploadContent(data).then(response => {
                if (response.status === 201) {
                    this.props.history.push("/editor/content/")
                } else {
                    Toast('error', response.data.error)
                }
            });
        } else {
            Toast('error', 'Please fill out all of the required fields.')
        }
    }  

    render() {
        return (
            <Container style={{ padding: '2% 3%' }}>
                <CustomToast />
                <Box component='div' p={2} style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
                    <FinalContentForm handleUpload={this.handleUpload}/>
                </Box>
            </Container>
        )
    }
}

export default UploadPage;