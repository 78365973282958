import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { ColumnToRow, Row, Item } from '@mui-treasury/components/flex';
import { NavMenu, NavItem } from '@mui-treasury/components/menu/navigation';
import {
  SocialProvider,
  SocialLink,
} from '@mui-treasury/components/socialLink';

import { useMoonSocialLinkStyles } from '@mui-treasury/styles/socialLink/moon';
import { usePlainNavigationMenuStyles } from '@mui-treasury/styles/navigationMenu/plain';
import Logo from '../../static/vlogo.png'

const useStyles = makeStyles(({ typography, palette, breakpoints }) => ({
    footer:{
        backgroundColor: 'black',
        color: '#888'    
    },
    content: {
        color: '#888',
        "&:hover": {
            color: 'white'
        }
    },
    social: {
        color: '#888',
        borderColor: '#888',
        "&:hover": {
            color: 'white',
            borderColor: 'white',
        }
    },
    menuItem: {
        color: '#888',
        "&:hover": {
            backgroundColor: 'rgba(255, 255, 255, 0.4)',
            color: 'white'
        },
    },
  legalLink: {
    ...typography.caption,
    justifyContent: 'center',
    color: '#888',
    position: 'relative',
    "&:hover": {
        color: 'white'
    },
    [breakpoints.up('sm')]: {
      '&:not(:first-of-type)': {
        '&:before': {
          content: '"|"',
          display: 'block',
          position: 'absolute',
          left: 0,
        },
      },
    },
  },
  newsletter: {
    fontSize: typography.caption.fontSize,
  },
  navMenu: {
    flexWrap: 'wrap',
  }
}));


const MenuItems = [
    {
        "label": "Home",
        "href": "/",
        "authenticated": false
    },
    {
        "label": "About",
        "href": "/about",
        "authenticated": false
    },
    {
        "label": "Contact",
        "href": "/about#contact",
        "authenticated": false
    },
    {
        "label": "The Developing Story",
        "href": "/developing",
        "authenticated": false
    },
    {
        "label": "Story Search",
        "href": "/content",
        "authenticated": false
    },
    {
        "label": "Log In",
        "href": "/login",
        "authenticated": false
    },
    {
        "label": "Sign Up",
        "href": "/signup",
        "authenticated": false
    },
]


const Footer = () => {
    const classes = useStyles();

    const getLogo = () => {
        return (
            <Box component='div' width='8vh' height='inherit'>
                <img style={{width: 'inherit'}} src={Logo} alt="logo"/>
            </Box>
        )
    }

    return (
        <Box className={classes.footer} width={'100%'} style={{ position: 'absolute' }}>
            <Container>
                <Box pt={8} pb={2}>
                    <Row wrap>
                        <Item grow={2}>
                            <Row alignItems={'center'}>
                                <Item color={'#007bff'} fontSize={64} lineHeight={0} marginRight='15px'>
                                    {getLogo()}
                                </Item>
                                <Item>
                                    <Typography variant={'h6'} className={classes.content}>
                                        Vettage
                                    </Typography>
                                </Item>
                            </Row>
                            <NavMenu className={classes.navMenu} style={{ marginTop: '15px'}}>
                                {MenuItems.map(item => {
                                    return (
                                        <NavItem className={classes.content, classes.menuItem} href={item.href}>{item.label}</NavItem>
                                    )
                                })}
                            </NavMenu>
                        </Item>
                        <Item grow maxWidth={500} mx={'auto'}>
                            <Box textAlign={'center'} mt={{ xs: 2, md: 0 }} my={2}>
                                <SocialProvider useStyles={useMoonSocialLinkStyles}>
                                <SocialLink
                                 className={classes.social}
                                    brand={'Twitter'}
                                    href={'https://twitter.com/vettage'}
                                />
                                <SocialLink 
                                 className={classes.social}
                                    brand={'Instagram'} 
                                    href={'https://www.instagram.com/vettage/'} 
                                />
                                </SocialProvider>
                            </Box>
                            <Typography  className={classes.content, classes.newsletter}>
                                Subscriber-Rated, Monthly Collaborative Reporting
                            </Typography>
                        </Item>
                    </Row>
                </Box>
                <Divider style={{ backgroundColor: '#888' }}/>
                <Box pt={2} pb={3}>
                    <ColumnToRow
                        at={'md'}
                        columnStyle={{ alignItems: 'center' }}
                        rowStyle={{ alignItems: 'unset' }}
                    >
                        <Item grow ml={-2} shrink={0}>
                            <NavMenu useStyles={usePlainNavigationMenuStyles} >
                                <ColumnToRow at={'sm'}>
                                    <NavItem href='/user-agreement' className={cx(classes.legalLink)}>
                                        User Agreement
                                    </NavItem>
                                    <NavItem href='/privacy' className={cx(classes.legalLink)}>
                                        Privacy Policy
                                    </NavItem>
                                    <NavItem href='/rules' className={cx(classes.legalLink)}>
                                        Rules
                                    </NavItem>
                                    <NavItem href='/legal' className={cx(classes.legalLink)}>Legal</NavItem>
                                </ColumnToRow>
                            </NavMenu>
                        </Item>
                        <Item>
                            <Box py={1} textAlign={{ xs: 'center', md: 'right' }}>
                                <Typography
                                component={'p'}
                                variant={'caption'}
                                className={classes.content}
                                >
                                Developed by {" "}
                                <a className={classes.content} href='https://mijoski.com'>Mijoski</a> & 
                                Perfected by {" "}
                                <a className={classes.content} href='https://boondainc.com'>Tim Borden</a> © Vettage LLC {new Date().getFullYear()} All rights reserved
                                </Typography>
                            </Box>
                        </Item>
                    </ColumnToRow>
                </Box>
            </Container>
        </Box>
    )
};

export default Footer;
