import { Component } from "react";
import '../styles/RulesPage.css'


class LegalPage extends Component{
    render(){
        return (
            <div className='main'>
                <h1>Vettage Legal</h1>
                <p>
                    For all content-related legal issues involving submissions using IPFS, please visit here.
                    For any other Copyright-oriented issues, please refer to the original link (in the Ratings window, “View Original”) 
                    for where the content is actually hosted. <br/><br/>
                    Prior to Submission, all Editors verify not only that they are Copyright owners, but also who they
                    share the Copyright with. Raw Media Contributors affirm that they are the original owners of all 
                    content accessed on Vettage by links they provide, and as such, by submitting said link(s) affirm 
                    their permission for Editors on Vettage to use that content in Final Submissions, provided Editors 
                    include their username as a Contributor when submitting Final Pieces, are ultimately responsible for 
                    resolving any disputes involving use of their submitted raw media. If a Raw Media Contributor contacts 
                    Vettage directly to resolve such a dispute, and if Vettage finds the raw content was used by an Editor 
                    without accreditation at the moment of submission, Vettage may alter the rewards from Subscriptions in 
                    the Raw Media contributor’s favor and ban the Editor from future use of the site. 
                </p>
            </div>
        )
    }
}

export default LegalPage