import { Component } from "react";
import { Grid, TextField, withStyles } from '@material-ui/core'
import VLogo from '../static/vlogo_old.gif'
import '../styles/RulesPage.css'
import EditIcon from '@material-ui/icons/Edit'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import RateReviewIcon from '@material-ui/icons/RateReview'
import TwitterIcon from '@material-ui/icons/Twitter'
import Shannon from '../static/shan.jpg'
import {Toast, CustomToast} from '../components/toastify'
import UtilServices from '../services/utilServices'

const CssTextField = withStyles({
    root: {
      '& label': {
          color: 'white'
      },
      '& label.Mui-focused': {
        color: '#fed108',
      },
      '& .MuiOutlinedInput-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        color: 'white',
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: '#fed108',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#fed108',
        },
      },
      '& .MuiFormHelperText-root':{
          color: 'white'
      }
    }
})(TextField);


class AboutPage extends Component{
    constructor(props){
        super(props)

        this.state = {
            name: "",
            email: "",
            message: ""
        }
    }

    sendForm = () => {
        if(this.state.name !== "" && this.state.email !== "" && this.state.message !== ""){
            UtilServices.sendForm(this.state.name, this.state.email, this.state.message)
            .then(response => {
                if(response.status === 200){
                    Toast('success', "The form has been submitted.")
                    this.setState({
                        name: "",
                        email: "",
                        message: ""
                    })
                }else{
                    Toast('error', "There was a problem with the form. Please try again later")
                }
            })
        }else{
            Toast('error', 'All of the fields are required')
        }
    }


    render(){
        return (
            <div>
                <CustomToast/>
                <Grid container lg={12} md={12} sm={12} xs={12}>
                    <Grid item lg={3} md={3} sm={6} xs={12} className='grid-item'>
                        <div>
                            <h2 style={{color: 'white'}}>WHAT IS VETTAGE?</h2>
                            <div style={{color: '#888'}}>
                                <p>
                                Vettage is a monthly, global collaborative reporting Software-as-a-Service (SaaS).
                                <br/><br/>
                                Subscribers can rate each news story based on 4 Merits: Newsworthiness, Credibility, 
                                Timeliness, and Presentation. Their ratings directly determine the percentage of their 
                                subscription payment which goes to the stories they choose.
                                <br/><br/>
                                Vettage also allows collaboration teams of journalists, raw content contributors and 
                                editors (video editors, web designers, writers, and other content editors) to connect and 
                                create their news piece submissions.
                                </p>
                                <img style={{width: '100%'}} src={VLogo}/>
                                <p>
                                LOGO
                                <br/><br/>
                                The VETTAGE logo was originally conceived as a visual representation of the three types of users 
                                and their relationships, with Subscribers as its main center of support (instead of advertising 
                                or government subsidy).
                                <br/><br/>
                                It is also a common icon denoting sharing, but rotated 90 degrees left.
                                <br/><br/>
                                The "V" is also associated with the Roman numeral V for 5 that represented the 
                                 5th Column resistance against Nazis in France, and its liberal use as the "peace" symbol proliferated&nbsp;
                                <a href='https://en.wikipedia.org/wiki/V_sign' 
                                style={{ color: '#fed108' }}>at least since World War II, and even as early as 1415.</a>
                                </p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12} className='grid-item'>
                        <div>
                            <h2 style={{color: 'white'}}>CHOOSE YOUR ROLE</h2>
                            <div className='role-item'>
                                <div>
                                    <Grid container lg={12} md={12} sm={12} xs={12}>
                                        <Grid lg={2} md={2} sm={3} xs={3}>
                                            <div className='icon-outer'>
                                               <EditIcon fontSize="medium" className='icon'/>
                                            </div>
                                        </Grid>
                                        <Grid lg={10} md={10} sm={9} xs={9}>
                                            <h5 style={{color: '#fed108', marginTop: '0px'}}>EDITOR</h5>
                                            <p style={{color: '#888'}}>
                                                Connect and collaborate with Raw Content Contributors to enrich the story. 
                                                Compose your final piece and submit for review by Subscribers. Earn money 
                                                based on your ratings in the four criteria, and distribute the winnings amongst 
                                                your collaborators based on the percentages agreed upon prior to composition.
                                                <br/>
                                                NOTE: Due to their ability to submit multiple stories on different subjects 
                                                utilizing multiple ground-based sources within a given monthly rating period, 
                                                Editors are allowed a maximum of 20% of all earnings for a given story.
                                            </p>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            <div className='role-item'>
                                <div>
                                    <Grid container lg={12} md={12} sm={12} xs={12}>
                                        <Grid lg={2} md={2} sm={3} xs={3}>
                                            <div className='icon-outer'>
                                               <PhoneIphoneIcon fontSize="medium" className='icon'/>
                                            </div>
                                        </Grid>
                                        <Grid lg={10} md={10} sm={9} xs={9}>
                                            <h5 style={{color: '#fed108', marginTop: '0px'}}>RAW CONTENT CONTRIBUTOR</h5>
                                            <p style={{color: '#888'}}>
                                                Writers / Photographers / Videographers / Sound Recordists / Graphic Designers / 
                                                Data Journalists / Musicians, any and/or all of the above. If you have the Raw
                                                 Content an Editor can use for a story, connect with one today and get paid up 
                                                 to 80% of the winnings for your diligence as the story's vital "boots on the ground."
                                            </p>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            <div className='role-item'>
                                <div>
                                    <Grid container lg={12} md={12} sm={12} xs={12}>
                                        <Grid lg={2} md={2} sm={3} xs={3}>
                                            <div className='icon-outer'>
                                               <RateReviewIcon fontSize="medium" className='icon'/>
                                            </div>
                                        </Grid>
                                        <Grid lg={10} md={10} sm={9} xs={9}>
                                            <h5 style={{color: '#fed108', marginTop: '0px'}}>SUBSCRIBER</h5>
                                            <p style={{color: '#888'}}>
                                                Journalism is about serving the public interest.<br/><br/>
                                                When you subscribe, you do so because you are fed up with media bias and 
                                                special interest influence, and you want the kind of quality reportage which 
                                                has been lost to budget cuts and ad-driven infotainment.
                                                <br/><br/>
                                                Your ratings ensure the reporting on VETTAGE is important, accurate, timely and 
                                                aesthetically, emotionally and intellectually engaging.
                                                <br/><br/>
                                                Your decisions are too important to be based on anything less.
                                            </p>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            <h2 style={{color: 'white'}}>FREQUENTLY ASKED QUESTIONS / TUTORIALS</h2>
                            <div style={{color: '#888'}} className='site-links'>
                                <h3>Visit our <a href="https://www.youtube.com/channel/UCvaJAFmm00KLyXE6ytPjUIA" 
                                    target="_new" class="more-link">YouTube Channel</a> for regular updates.
                                </h3>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12} className='grid-item'>
                        <div>
                            <h2 style={{color: 'white'}}>HOW TO RATE A NEWS STORY</h2>
                            <div style={{color: '#888'}}>
                                <ul style={{ listStyleType: 'none', padding: '0 10px' }}>
                                    <li>
                                        <h5 className='rating-title'>- NEWSWORTHINESS</h5>
                                        <p className='rating-content'>Is it the truth? Is it important to the public? What are the consequences if it is 
                                            made public versus if it is not? Does it afford a continued discourse toward legislation 
                                            to address the issue(s) according to the concerns raised by the story?</p>
                                    </li>
                                    <li>
                                        <h5 className='rating-title'>- CREDIBILITY</h5>
                                        <p className='rating-content'>Where and how did the story originate? How much of it is opinion versus how 
                                            much is fact? Is it politically or ideologically motivated, composed by 
                                            "cherry picking" facts to serve a political or partisan agenda? Why should 
                                            we trust the veracity of the story? Is there a moral imperative to the story 
                                            that supersedes partisanship?</p>
                                    </li>
                                    <li>
                                        <h5 className='rating-title'>- TIMELINESS</h5>
                                        <p className='rating-content'>How much impact does the story have on the issue(s) it is concerned with? 
                                            How much time has elapsed since the story originated versus when it was made public?</p>
                                    </li>
                                    <li>
                                        <h5 className='rating-title'>- PRESENTATION</h5>
                                        <p className='rating-content'>How much does the presentation and design of the information make the story easier to 
                                            understand? Is it interactive? Is it technically competent, without bugs or obstacles
                                             to viewing? Does it translate well across different devices? How well does it bypass 
                                             censorship by governments or other actors?</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12} className='grid-item'>
                        <div>
                            <h2 style={{color: 'white'}}>EDITORIAL RESOURCES</h2>
                            <div style={{color: '#888'}} className='site-links'>
                            <h3>There are myriad tools to build, enhance, verify and establish the credibility of your content before it's presented it to the public.
                            </h3> 
                                <h3 className='link-title'>Sites</h3>
                                <p><a href="https://www.poynter.org/" Target="_new" class="more-link">Poynter</a></p>
                                <p><a href="http://FactCheck.org">FactCheck.org</a></p>
                                <p><a href="https://sites.google.com/view/reverse-images/home">Google Reverse Image Search</a></p>
                                <p><a href="http://fullfact.org/">Full Fact Finder</a></p>
                                <p><a href="https://www.witness.org/resources/">Witness.org Resources</a></p>    
                                <p><a href="http://www.emergent.info/">Emergent</a></p>
                                <p><a href="https://www.ibanet.org/news_analysis.aspx">International Bar Association News analysis</a></p>
                                <p><a href="http://www.lazytruth.com/">LazyTruth</a></p>
                                <p>Is Twitter Wrong? (Currently Tom Phillips <br/><a href="https://x.com/flashboy">@flashboy on X</a></p>
                                <h3 className='link-title'>Communications Security</h3>
                                <p><a href="https://www.openpgp.org/software/">Pretty Good Privacy</a></p>
                                <p><a href="https://tails.boum.org/">Tails</a></p>
                                <p><a href="https://ipfs.io">Interplanetary File System</a><br/>-- a decentralized content addressing protocol which was <a href="https://observer.com/2017/05/turkey-wikipedia-ipfs/">used to bypass Turkish censorship</a></p>
                                <h3 className='link-title'>Music and Sound</h3>
                                <p><a href="https://freesound.org/">FreeSound</a></p>
                                <h3 className='link-title'>Imagery</h3>
                                <p><a href="http://www.tineye.com/">TinEye</a></p>
                                <p><a href="http://images.google.com/">Google Images</a></p>
                                <p><a href="http://blog.witness.org/2012/02/introducing-informacam-the-next-release-of-the-securesmartcam-project/">InformaCam</a></p>
                                <p><a href="https://github.com/guardianproject/CameraV">"An InformaCam app to generate verifiable media." (GitHub)</a></p>
                                <h3 className='link-title'>Reporting and Research</h3>
                                <p><a href="https://www.governmentattic.org/">Government Attic</a></p>
                                <p><a href="https://po.missouri.edu/cgi-bin/wa?A0=IREPLUS-L">INVESTIGATIVE REPORTERS AND EDITORS (IRE) - Plus Mailing List (Requires membership)</a></p>
                                <p><a href="https://www.lexisnexis.com/en-us/home.page">LexisNexis (commercially available research tools)</a></p>    
                                <p><a href="http://www.nickdiakopoulos.com/projects/finding-and-assessing-journalistic-sources-in-social-media/">SRSR</a></p>
                                <p><a href="https://www.thumbtack.com">Find top-rated private investigators in your area</a></p>
                                <p><a href="http://memetracker.org/">MemeTracker</a></p>
                                <p><a href="http://www.keepr.com/">Keepr</a></p>
                                <h3 className='link-title'>Academic Resources</h3>
                                <p><a href="https://crsreports.congress.gov/">Congressional Research Service</a></p>
                                <p><a href="http://verificationhandbook.com/">The Verification Handbook</a></p>
                                <p><a href="https://verificationjunkie.com/">Verification Junkie</a></p>
                                <h3 className='link-title'>Business Intelligence</h3>
                                <p><a href="https://www.oxan.com/">Oxford Analytica</a></p>
                                <p><a href="https://www.eiu.com/n/">Economist Intelligence Unit</a></p>
                                <p><a href="https://www.findsvp.com/">Find SVP</a></p>
                                <p><a href="https://www.sisinternational.com/">SIS International</a></p>
                                <p><a href="https://www.tmusallc.com/">TM USA</a></p>
                                <p><a href="https://www.scip.org/">Strategic and Competitive Intelligence Professionals</a></p>
                                <h3 className='link-title'>Global Policy and Crime Statistics</h3>
                                <p><a href="https://worldview.stratfor.com/">Stratfor (may be politically biased)</a></p>
                                <p><a href="https://havocscope.com/">Havocscope - Global Black Market Financials</a></p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12} className='grid-item'>
                        <div style={{color: '#888'}}>
                            <img style={{width: '100%'}} src={Shannon}/>
                            <h3 style={{color: 'white', margin: '10px 0 0'}}>Shannon Lowell Frady</h3>
                            <p style={{margin: '0'}}>Founder</p>
                            <br/>
                            <p>
                                Son of Pulitzer-nominated civil rights journalist Marshall Frady, Shannon grew up in 
                                New York City and worked in Beijing as a commercial and editorial photographer and 
                                producer for seven years before returning to the U.S. in 2008 to join the U.S. Army as 
                                a Signals Intelligence Analyst and Reporter, holding a TS/SCI clearance.
                                <br/><br/>
                                After a long hiatus from Syracuse University, he later finished his degree in Journalism and Mass 
                                Communication at ASU's Walter Cronkite School, graduating Magna Cum Laude in 2014. 
                            </p>
                            <div className='twitter-icon'>
                                <a href='https://twitter.com/vettage' >
                                    <TwitterIcon  fontSize="medium" className='twitter-svg'/>
                                </a>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12} className='grid-item'>
                        <div id='contact'>
                            <div>
                                <h2 style={{color: 'white'}}>CONTACT</h2>
                            </div>
                            <div className='form-item'>
                            <CssTextField
                                required
                                id="name"
                                label="Name*"
                                variant="outlined"
                                name="name"
                                fullWidth
                                value={this.state.name}
                                onChange={e => this.setState({ name: e.target.value })}
                            /></div>
                            <div className='form-item'>
                            <CssTextField
                                required
                                id="email"
                                label="Email*"
                                variant="outlined"
                                name="email"
                                fullWidth
                                value={this.state.email}
                                onChange={e => this.setState({ email: e.target.value })}
                                /></div>
                            <div className='form-item'>
                            <CssTextField
                                required
                                id="message"
                                label="Message*"
                                variant="outlined"
                                name="message"
                                fullWidth
                                multiline
                                rows={4} 
                                value={this.state.message}
                                onChange={e => this.setState({ message: e.target.value })}
                            />
                            </div>
                            <div style={{textAlign: 'center'}}>
                                <button className='form-button' onClick={this.sendForm}>SEND</button>
                            </div>
                        </div> 
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default AboutPage
