import { Component } from "react";
import ContentService from "../services/contentService";
import { CustomToast, Toast } from '../components/toastify'
import { Box, Container, Grid, Button, Card, withStyles  } from '@material-ui/core'
import ContentSearch from '../components/ContentComponents/ContentSearch'
import ReactPaginate from 'react-paginate'
import ContentView from '../components/ContentComponents/ContentView'
import { withHooksHOC } from '../components/withHooksHOC'
import ContentSingleItem from '../components/ContentComponents/ContentSingleItem'

const styles = theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      width: '80vh',
      height: 450,
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    button: {
          padding: '0px',
          color: '#fed108',
          backgroundColor: 'black',
          border: '1px solid #fed108',
          '& span':{
            padding: '8px 15px'
          },
          '& :hover':{
            color: 'black',
            backgroundColor: '#fed108'
          }
    }
});

const isAuthenticated = () => {
    if(localStorage.getItem('vettageToken') && localStorage.getItem('vettageToken') !== "undefined"){
        return true
    }
    return false
}

class ContentPage extends Component{
    constructor(props){
        super(props)

        this.state = {
            content: [],
            count: 0,
            currentPage: 0,
            params: "",
            open: false,
            rowsPerPage: 9,
            selectedContent: null,
            drawerOpen: true,
        }
    }
    
    redirectStory = item => {
        this.setState({
            open: true,
            selectedContent: item
        })
    }

    onClose = () => {
        this.setState(prevState => ({ open: !prevState.open}))
    }

    handleData = (data) => {
        this.setState({ content: data }, () => this.dataToRender())
    }

    componentDidMount(){
      this.dataToRender()
    }

    dataToRender = () => {
        ContentService.getContent(this.state.params, this.state.rowsPerPage, this.state.currentPage + 1).then(result => {
            if(result.status === 200){
                this.setState({ 
                    content: result.data.results,
                    count: result.data.count
                 })
            }else if(result.status === 403){
                this.props.history.push("/404")
            }else if(result.status === 400){
                Toast("error", "There was an error. Please try again later.")
            }
        })
    }   
  
    handlePagination = page => {    
        this.setState({ currentPage: page.selected }, () => this.dataToRender())
    }

    CustomPagination = () => {
        const count = Number(Math.ceil(this.state.count / this.state.rowsPerPage))
      
        return (
        <ReactPaginate
            pageCount={count || 1}
            nextLabel='&rarr;'
            breakLabel='...'
            previousLabel='&larr;'
            activeClassName='active'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            forcePage={this.state.currentPage}
            onPageChange={page => this.handlePagination(page)}
            pageClassName={'page-item'}
            nextLinkClassName={'page-link'}
            nextClassName={'page-item next'}
            previousClassName={'page-item prev'}
            previousLinkClassName={'page-link'}
            pageLinkClassName={'page-link'}
            containerClassName={'pagination react-paginate justify-content-end p-1'}
        />
        )
    }

    addRatingData = (newData) => {
        this.setState(prevState => ({
            content: prevState.content.map(el => (el.id === newData.id ? newData : el)),
            selectedContent: newData
          }));
    }

    render(){
        return(
            <Container style={{ padding: '2% 0' }}>
                <Card style={{ padding: '1% 1%', minHeight: '80vh', backgroundColor: 'rgba(0, 0, 0, 0.8)'}}>
                <CustomToast />
                {isAuthenticated() &&
                    <Box marginTop={2}>
                        <Button className={this.props.classes.button} size='large' onClick={() => this.setState({ drawerOpen: true })}>Search</Button>
                        <Box component='div' fullWidth>
                            <ContentSearch open={this.state.drawerOpen} onClose={() => this.setState({ drawerOpen: false })} setData={this.handleData} setParams={e => this.setState({ params: e }, () => this.dataToRender())}/>
                        </Box>
                    </Box>
                }
                <ContentView open={this.state.open} onClose={this.onClose} data={this.state.selectedContent} setData={this.addRatingData}/>
                <Grid container lg={12} md={12} >
                    {this.state.content && this.state.content.map((tile) => (
                        <Grid item lg={3} md={5} sm={5} xs={12} style={{ margin: '15px auto', minWidth: '380px' }} onClick={() => this.redirectStory(tile)}>
                            <Box maxWidth={this.props.width < 960 ? "50vh" : null} margin='auto'>
                                <ContentSingleItem item={tile} />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                {this.state.count > this.state.rowsPerPage && 
                    <this.CustomPagination/>
                }
                </Card>
            </Container>
        )}
}

export default withHooksHOC(withStyles(styles)(ContentPage));