import React from 'react';
import GoogleFontLoader from 'react-google-font-loader';
import NoSsr from '@material-ui/core/NoSsr';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import {
  Info,
  InfoCaption,
  InfoSubtitle,
  InfoTitle,
} from '@mui-treasury/components/info';
import { useGalaxyInfoStyles } from '@mui-treasury/styles/info/galaxy';
import { useCoverCardMediaStyles } from '@mui-treasury/styles/cardMedia/cover';
import moment from 'moment'

const useStyles = makeStyles(() => ({
  card: {
    cursor: 'pointer',
    borderRadius: '1rem',
    boxShadow: 'none',
    position: 'relative',
    margin: 'auto',
    minHeight: 450,
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '64%',
      bottom: 0,
      zIndex: 1,
      background: 'linear-gradient(to top, #000 20%, rgba(0,0,0,0))',
    },
  },
  content: {
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    width: '100%',
  },
}));

export const GalaxyCardDemo = ({ item }) => {
  const mediaStyles = useCoverCardMediaStyles({ bgPosition: 'top' });
  const styles = useStyles();
  return (
    <>
      <NoSsr>
        <GoogleFontLoader
          fonts={[
            { font: 'Spartan', weights: [300] },
            { font: 'Montserrat', weights: [200, 400, 700] },
          ]}
        />
      </NoSsr>
      <Card className={styles.card}>
        <CardMedia
          classes={mediaStyles}
          image={item.thumbnail}
        />
        <Box py={3} px={2} className={styles.content}>
          <Info useStyles={useGalaxyInfoStyles}>
            <InfoSubtitle style={{ fontSize: '13px'}}>{moment(item.created_at).format('DD MMM YYYY')}</InfoSubtitle>
            <InfoTitle style={{ fontSize: '17px', wordWrap: 'break-word', maxWidth: '70%', margin: 'auto'}}>
              {item.title?.length > 5 ? item.title.substring(0, 55) + "..." : item.title }</InfoTitle>
            <InfoCaption>By: {item.editor}</InfoCaption>
          </Info>
        </Box>
      </Card>
    </>
)};

export default GalaxyCardDemo