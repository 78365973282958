import { Component } from 'react';
import validator from 'validator';
import EditorService from '../../services/editorsService';
import FinalContentForm from "./FinalContentForm";
import { Container, Box, Grid } from '@material-ui/core'
import { CustomToast, Toast } from '../../components/toastify'
import RatingsTable from '../../components/RatingComponents/list/index'

class EditPage extends Component{
    constructor(props) {
        super(props)

        this.state = {
            content: null,
        }

      }

    componentDidMount(){
        if(this.props.location.data){
            this.setState({ content: this.props.location.data })
        }else{
            EditorService.getContentById(this.props.location.pathname.split('/')[3])
            .then(response => {
                if(response.status === 200){
                    this.setState({
                        content: response.data,
                    })
                }else{
                    Toast('error', "There was an error with the request. Please try again later.")
                }

            })
        }
    }

    checkValidUrl = (url) => {
        var types = ['jpg','jpeg','tiff','png','gif','bmp'];
        var parts = url.split('.');
        
        var extension = parts[parts?.length-1];

        if(types.indexOf(extension) !== -1) {
            return true;   
        }else{
            return false;
        }
    }

    handlePatch = (e, data) => {
        e.preventDefault();

        if(
            data.content_type !== "" &&
            data.tags?.length !== 0 &&
            validator.isURL(data.link, {require_valid_protocol: false}) &&
            this.checkValidUrl(data.thumbnail) &&
            validator.isURL(data.preview, {require_valid_protocol: false}) &&
            this.checkValidUrl(data.preview) &&
            data.copyright == true &&
            data.final_content_address.country !== "" &&
            data.final_content_address.state !== "" &&
            data.final_content_address.city !== "" &&
            data.description !== "" &&
            data.title !== ""
            && data.link !== ""
        ){
            if(!data.preview.includes('http') && !data.preview.includes('https')){
                data.preview = "http://" + data.preview
            }
            if(!data.thumbnail.includes('http') && !data.thumbnail.includes('https')){
                data.thumbnail = "http://" + data.thumbnail
            }
            if(!data.link.includes('http') && !data.link.includes('https')){
                data.link = "http://" + data.link
            }

            if(data.link.includes('vimeo.com')){
                let oldLink = data.link
                data.link = 'https://player.vimeo.com/video/' + oldLink.split('/')[oldLink.split('/').length-1]
            }

            EditorService.patchContent(data).then(response => {
                if (response.status === 200) {
                    this.props.history.push("/editor/content/")
                } else {
                    Toast('error', response.data.error)
                }
            })
        } else {
          Toast('error', 'Please fill out all of the required fields.')
        }
    }  

    render() {
        return (
            <Container style={{ padding: '2% 3%' }}>
                <CustomToast />
                <Box component='div' p={2} style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
                <Grid container lg={12} md={12}>
                    <Grid item lg={5} md={5} xs={12} sm={12}>
                        <Box component='div' m={2} p={2}>
                            {this.state.content &&  <RatingsTable id={this.state.content.id}/>}
                        </Box>
                    </Grid>
                    <Grid item lg={7} md={7} xs={12} sm={12}>
                        <Box component='div' m={2} p={2}>
                            {this.state.content &&  <FinalContentForm handleUpload={this.handlePatch} data={this.state.content}/>}
                        </Box>
                    </Grid>
                </Grid>
                </Box>
            </Container>
        )
    }
}

export default EditPage;