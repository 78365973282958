import axios from './custom-axios';
import AuthService from './authService';

class ContentService {

   getContent(params, limit, page){
    return axios.get("content/final/?" + params + "page=" + page + "&limit=" + limit,  { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
   }

   

   rateContent(finalContentId, newsworthiness, credibility, timeliness, designQuality){
       return axios.post('content/' + finalContentId + "/ratings/", {
        "newsworthiness": newsworthiness/10,
        "credibility": credibility/10,
        "timeliness": timeliness/10,
        "design_quality": designQuality/10
       },
       { validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
   }

   checkPermission(final_content_id){
       return axios.get('content/permission/', { params: { final_content_id }, validateStatus: status => {return status < 500}, headers: AuthService.authHeader() })
   }
}

export default new ContentService();